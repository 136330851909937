import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import queryString from "query-string"
import { navigate } from "gatsby"

const cleanCourseName = eventTypeName => {
  // Remove variations of "Trial Class"
  const cleanedName = eventTypeName
    .replace(/\s*Trial\s*Class$/i, "") // Removes "Trial Class" at the end
    .replace(/\s*Trial\s*Class\s*/i, "") // Removes "Trial Class" anywhere
    .trim() // Remove any extra whitespace

  return cleanedName
}
const GOOGLE_SCRIPT_URL =
  "https://script.google.com/macros/s/AKfycby7pJCYX36alGrjpB0UawcGVRRb6IruCDFp-yONYxjR6elcLUu3ztkvBCuuDJxlflgFjQ/exec"
const ThankYouPage = () => {
  const [isValidAccess, setIsValidAccess] = useState(false)
  const [isLogged, setIsLogged] = useState(false)

  useEffect(() => {
    // Check for required parameters
    const parsed = queryString.parse(window.location.search)

    // Define the required parameters
    const requiredParams = [
      "event_type_name",
      "event_start_time",
      "event_end_time",
      "invitee_email",
    ]

    // Check if all required parameters are present
    const hasRequiredParams = requiredParams.every(
      param => parsed[param] && parsed[param].trim() !== ""
    )

    if (!hasRequiredParams) {
      // Redirect to home page if parameters are missing
      navigate("/")
    } else {
      setIsValidAccess(true)
    }

    // Prepare data for logging
    const dataToLog = {
      eventTypeName: parsed.event_type_name,
      eventStartTime: parsed.event_start_time,
      eventEndTime: parsed.event_end_time,
      inviteeName: parsed.invitee_full_name || "",
      inviteeEmail: parsed.invitee_email,
      contactNumber: parsed.answer_1 || "",
    }

    // Log data to Google Sheet
    logToGoogleSheet(dataToLog)
  }, [])

  const logToGoogleSheet = async data => {
    try {
      const response = await fetch(GOOGLE_SCRIPT_URL, {
        method: "POST",
        mode: "no-cors", // Important for Google Apps Script
        cache: "no-cache",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })

      // Since it's no-cors, we can't read the response
      // But we can assume success if no error is thrown
      setIsLogged(true)
    } catch (error) {
      console.error("Error logging to Google Sheet:", error)
      // Optionally, implement a retry mechanism or error handling
    }
  }

  const [eventDetails, setEventDetails] = useState({
    assignedTo: "",
    eventTypeName: "",
    eventStartTime: "",
    eventEndTime: "",
    inviteeName: "",
    inviteeEmail: "",
    contactNumber: "",
  })

  useEffect(() => {
    const parsed = queryString.parse(window.location.search)

    setEventDetails({
      assignedTo: parsed.assigned_to
        ? decodeURIComponent(parsed.assigned_to)
        : "",
      eventTypeName: parsed.event_type_name
        ? decodeURIComponent(parsed.event_type_name)
        : "",
      eventStartTime: parsed.event_start_time
        ? decodeURIComponent(parsed.event_start_time)
        : "",
      eventEndTime: parsed.event_end_time
        ? decodeURIComponent(parsed.event_end_time)
        : "",
      inviteeName: parsed.invitee_full_name
        ? decodeURIComponent(parsed.invitee_full_name)
        : "",
      inviteeEmail: parsed.invitee_email
        ? decodeURIComponent(parsed.invitee_email)
        : "",
      contactNumber: parsed.answer_1 ? decodeURIComponent(parsed.answer_1) : "",
    })
  }, [])

  const courseName = cleanCourseName(eventDetails.eventTypeName)

  const formatDateTime = isoString => {
    if (!isoString) return ""
    const date = new Date(isoString)
    return {
      dayOfWeek: date.toLocaleDateString("en-US", { weekday: "long" }),
      date: date.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      }),
      time: date.toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      }),
    }
  }

  const formattedStartTime = formatDateTime(eventDetails.eventStartTime)
  const formattedEndTime = formatDateTime(eventDetails.eventEndTime)

  const styles = {
    pageContainer: {
      fontFamily: "'Montserrat', sans-serif",
      // background: "linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)",
      minHeight: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "20px",
    },
    container: {
      backgroundColor: "white",
      borderRadius: "20px",
      boxShadow: "0 10px 30px rgba(0,0,0,0.1)",
      maxWidth: "700px",
      width: "100%",
      padding: "40px",
      textAlign: "center",
      position: "relative",
      overflow: "hidden",
    },
    logo: {
      width: "150px",
      marginBottom: "20px",
      zIndex: 1,
      position: "relative",
    },
    confirmationIcon: {
      fontSize: "80px",
      color: "#e07845",
      marginBottom: "20px",
      zIndex: 1,
      position: "relative",
    },
    title: {
      color: "#333",
      fontSize: "28px",
      marginBottom: "15px",
      fontWeight: "700",
    },
    subtitle: {
      color: "#666",
      marginBottom: "30px",
      fontSize: "16px",
    },
    detailsContainer: {
      backgroundColor: "#f9f9f9",
      borderRadius: "15px",
      padding: "25px",
      marginBottom: "30px",
      border: "1px solid #e1e1e1",
    },
    detailItem: {
      display: "flex",
      justifyContent: "space-between",
      borderBottom: "1px solid #e1e1e1",
      padding: "12px 0",
    },
    detailLabel: {
      fontWeight: "600",
      color: "#555",
    },
    detailValue: {
      color: "#333",
      fontWeight: "500",
    },
    ctaButton: {
      backgroundColor: "#e07845",
      color: "white",
      border: "none",
      padding: "12px 25px",
      borderRadius: "30px",
      fontSize: "16px",
      cursor: "pointer",
      transition: "all 0.3s ease",
      boxShadow: "0 4px 15px rgba(224,120,69,0.3)",
    },
    illustrationContainer: {
      display: "flex",
      justifyContent: "center",
      marginBottom: "30px",
    },
    illustration: {
      maxWidth: "250px",
      height: "auto",
    },
  }

  const addToCalendar = () => {
    if (eventDetails.eventStartTime && eventDetails.eventEndTime) {
      const startTime = new Date(eventDetails.eventStartTime)
      const endTime = new Date(eventDetails.eventEndTime)

      const googleCalendarLink = `https://calendar.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(
        eventDetails.eventTypeName
      )}&dates=${startTime
        .toISOString()
        .replace(/[-:]/g, "")}/${endTime.toISOString().replace(/[-:]/g, "")}`

      window.open(googleCalendarLink, "_blank")
    }
  }

  // Your existing ThankYouPage component code
  const renderContent = () => {
    const parsed = queryString.parse(window.location.search)

    return (
      <Layout>
        <SEO title="Trial Class Scheduled" />
        <div style={styles.pageContainer}>
          <div style={styles.container}>
            {/* Illustration */}
            {/* <div style={styles.illustrationContainer}>
            <img
              src="/path-to-coding-illustration.png"
              alt="Coding Illustration"
              style={styles.illustration}
            />
          </div> */}

            <div style={styles.confirmationIcon}>✅</div>
            <h3 className="tittle text-center font-weight-bold">
              Trial Class <span className="title-span">Confirmed!</span>
            </h3>

            {/* <h1 style={styles.title}></h1> */}

            <p style={styles.subtitle}>
              Congratulations! Your trial class is officially scheduled. Please
              check your email for important details and next steps to make the
              most of your session. See you soon! 🥳
            </p>

            <div style={styles.detailsContainer}>
              <div style={styles.detailItem}>
                <span style={styles.detailLabel}>Course</span>
                <span style={styles.detailValue}>{courseName}</span>
              </div>

              <div style={styles.detailItem}>
                <span style={styles.detailLabel}>Date</span>
                <span style={styles.detailValue}>
                  {formattedStartTime.dayOfWeek}, {formattedStartTime.date}
                </span>
              </div>

              <div style={styles.detailItem}>
                <span style={styles.detailLabel}>Time</span>
                <span style={styles.detailValue}>
                  {formattedStartTime.time} - {formattedEndTime.time}
                </span>
              </div>

              {eventDetails.inviteeName && (
                <div style={styles.detailItem}>
                  <span style={styles.detailLabel}>Name</span>
                  <span style={styles.detailValue}>
                    {eventDetails.inviteeName}
                  </span>
                </div>
              )}

              <div style={styles.detailItem}>
                <span style={styles.detailLabel}>Email</span>
                <span style={styles.detailValue}>
                  {eventDetails.inviteeEmail}
                </span>
              </div>

              {eventDetails.contactNumber && (
                <div style={styles.detailItem}>
                  <span style={styles.detailLabel}>Contact Number</span>
                  <span style={styles.detailValue}>
                    {eventDetails.contactNumber}
                  </span>
                </div>
              )}
            </div>

            {/* <button
            style={styles.ctaButton}
            onClick={addToCalendar}
            onMouseOver={e => {
              e.target.style.transform = "translateY(-3px)"
              e.target.style.boxShadow = "0 6px 20px rgba(224,120,69,0.4)"
            }}
            onMouseOut={e => {
              e.target.style.transform = "translateY(0)"
              e.target.style.boxShadow = "0 4px 15px rgba(224,120,69,0.3)"
            }}
          >
            Add to Calendar
          </button> */}
          </div>
        </div>
      </Layout>
    )
  }

  // If access is not valid, return null or a loading state
  if (!isValidAccess) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          fontFamily: "Arial, sans-serif",
        }}
      >
        <p>Redirecting...</p>
      </div>
    )
  }

  return renderContent()
}

export default ThankYouPage
